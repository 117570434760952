
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  TASQ_NO_COMMS_TYPE,
  TASQ_PREDICTION_TYPE,
  TASQ_FAILURE_TYPE,
  TASQ_DOWN_TYPE,
  TASQ_OFF_TARGET_TYPE,
  TASQ_LABEL_TYPE,
  TASQ_PRODUCING_TYPE,
  TASQ_PM_TYPE,
  TASQ_GAUGE_TYPE,
  TASQ_REGULATORY_TYPE,
  TASQ_SITE_CHECK_TYPE,
  TASQ_SETPOINT_TYPE,
  TASQ_WELL_TEST_TYPE,
  TASQ_SCHEDULED_JOB,
  TASQ_FAILURE_PREDICTION_TYPE,
  TASQ_OFF_TARGET_RT_TYPE,
  TASQ_WORKFLOW_TYPE,
  TASQ_PLUNGER_CHANGE,
  TASQ_STANDING_VALVE,
  TASQ_WAITING_ON_DAYS_LEFT,
  TASQ_WAITING_ON_OVERDUE,
  TASQ_REAL_TIME_TYPE,
} from '@/lib/constants';

@Component
export default class TasqTag extends Vue {
  @Prop({ type: String, default: 'span' }) tagName!: string;

  @Prop({ type: String }) label!: string;

  get classesBasedOnLabel(): string {
    if (!this.label) {
      return '';
    }
      return this.getColorClass(this.label);
  }

  getColorClass = (predictionType) => {
    console.log(predictionType);
    switch (predictionType) {
      case TASQ_PLUNGER_CHANGE:
      case TASQ_STANDING_VALVE:
        return 'border border-blue005 text-blue005';
      case TASQ_PREDICTION_TYPE:
      case TASQ_FAILURE_PREDICTION_TYPE:
        return 'border border-green015 text-green015';
      case TASQ_FAILURE_TYPE:
      case TASQ_OFF_TARGET_RT_TYPE:
      case TASQ_REAL_TIME_TYPE:
        return 'border border-red001 text-red001';
      case TASQ_DOWN_TYPE:
      case TASQ_OFF_TARGET_TYPE:
        return 'border border-orange002 text-orange002';
      case TASQ_LABEL_TYPE:
        return 'border border-blue002 text-blue002';
      case TASQ_PRODUCING_TYPE:
      case TASQ_PM_TYPE:
      case TASQ_GAUGE_TYPE:
      case TASQ_REGULATORY_TYPE:
      case TASQ_SITE_CHECK_TYPE:
      case TASQ_NO_COMMS_TYPE:
      case TASQ_WELL_TEST_TYPE:
      case TASQ_SCHEDULED_JOB:
        return 'border border-blue039 text-blue039';
      case TASQ_WORKFLOW_TYPE:
      return 'border border-violet001 text-violet001';
      case TASQ_SETPOINT_TYPE:
        return 'border border-setpointchange text-setpointchange';
      case TASQ_WAITING_ON_DAYS_LEFT:
        return 'bg-accentWarning200 text-tasqNeutral1000';
      case TASQ_WAITING_ON_OVERDUE:
        return 'bg-accentError200 text-tasqNeutral1000';
      default:
        return 'border border-gray008 text-gray008';
    }
  };
}
