import { ucfirst } from '@/utils/helpers';
import {
  TASQ_NO_COMMS_TYPE,
  TASQ_PREDICTION_TYPE,
  TASQ_FAILURE_TYPE,
  TASQ_DOWN_TYPE,
  TASQ_OFF_TARGET_TYPE,
  TASQ_LABEL_TYPE,
  TASQ_PRODUCING_TYPE,
  TASQ_PM_TYPE,
  TASQ_GAUGE_TYPE,
  TASQ_REGULATORY_TYPE,
  TASQ_SITE_CHECK_TYPE,
  TASQ_SCHEDULED_JOB_TYPE,
  TASQ_SETPOINT_TYPE,
  TASQ_WELL_TEST_TYPE,
  TASQ_SCHEDULED_JOB,
  TASQ_PROLONGED_ANOMALY_TYPE,
  TASQ_FAILURE_PREDICTION_TYPE,
  TASQ_STATE_CHANGE_TYPE,
  TASQ_OFF_TARGET_RT_TYPE,
  TASQ_WORKFLOW_TYPE,
  TASQ_PLUNGER_CHANGE,
  TASQ_STANDING_VALVE,
} from '@/lib/constants';
import TasqJobResponse from '@/graphql/predictions/interfaces/TasqJobResponse';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import { DateTime } from 'luxon';
import workflowModule from '@/store/modules/workflowModule';

const getStepOneCompleted = (tasq: TasqJob) => tasq?.responseData?.user_response?.selection;
const getStepTwoCompleted = (tasq: TasqJob) => tasq?.responseData?.user_response?.selection
  && tasq?.responseData?.user_response?.selection_details?.reason.length;
const getStepThreeCompleted = (tasq: TasqJob) => tasq?.responseData?.user_response?.selection
  && tasq?.responseData?.user_response?.selection_details?.reason
  && tasq?.responseData?.user_response?.selection_details?.action;
const getStepFourCompleted = (tasq: TasqJob) => tasq?.responseData?.user_response?.selection
  && tasq?.responseData?.user_response?.selection_details?.reason
  && tasq?.responseData?.user_response?.selection_details?.action
  && tasq?.responseData?.user_response?.corrected_state_change_date;

const getStepLabel = (tasq: TasqJob) => {
  let label = 'No action';
  if (tasq.stepOneCompleted) {
    label = 'Validated';
  }
  if (tasq.stepTwoCompleted) {
    label = 'Labeled';
  }
  if (tasq.stepThreeCompleted) {
    label = 'Actioned';
  }
  if (tasq.stepFourCompleted) {
    label = 'Resolved';
  }
  return label;
};

const getDateAgoText = (tasq: TasqJob) => {
  let date = '';
  // @ts-ignore
  const waiting = tasq?.snoozed?.wait_until_reason && tasq?.snoozed?.until_date;
  let time = tasq?.assignmentTime;
  if (waiting) {
    // @ts-ignore
    time = tasq?.snoozed.until_date;
  }
  if (tasq?.predictionType === TASQ_OFF_TARGET_TYPE) {
    time = tasq?.offTargetStartDate;
  }
  if (tasq?.predictionType === TASQ_STATE_CHANGE_TYPE
    || tasq?.predictionType === TASQ_PROLONGED_ANOMALY_TYPE
  ) {
    time = tasq?.stateChangeStart;
  }
  if (!time) {
    return date;
  }

  // @ts-ignore
  const timeVal = DateTime.fromISO(time);
  let value = DateTime.local().diff(timeVal, 'days').toObject()?.days?.toFixed();
  if (waiting) {
    value = timeVal.diff(DateTime.local(), 'days').days.toFixed();
  }

  if (value && value !== '0') {
    if(!Number.isNaN(Number(value)) && Number(value) > 365) {
      date = '> 365 days';
    } else {
      date = value === '1'
      ? `${value} day` : `${value} days`;
    }

    if (waiting) {
      date = `in ${date}`;
    }
  }
  if (value === '0' || value === '-0') {
    date = 'Today';
  }
  return {
    text: date,
    value: Number(value),
  };
};

const getOverriddenTasqName = (tasq: TasqJobResponse) => {
  switch (tasq.PredictionType) {
    case TASQ_PROLONGED_ANOMALY_TYPE:
    case TASQ_STATE_CHANGE_TYPE:
      return 'Anomaly';
    case TASQ_OFF_TARGET_RT_TYPE:
      return 'Real-Time';
    case TASQ_WELL_TEST_TYPE:
	  return 'Well test';
    case 'Off-Target RT':
      return 'REAL-TIME';
    default:
      // @ts-ignore
      return tasq.ID ? 'Scheduled Job' : ucfirst(tasq.PredictionType || '');
  }
};

const getTotalPadDeferment = (tasq: any) => {
  let total_deferment_count = 0;
  let localDefermentUnits = 'bpd';
  // console.log(tasq);

  if (tasq.Wells && tasq.Level === 'PAD') {
    for (let x = 0; x < tasq.Wells.length; x++) {
      if (tasq.Wells[x].Payload && tasq.Wells[x].Payload.MCF) {
        // console.log(tasq.Wells[x].Payload);
        localDefermentUnits = tasq.Wells[x].defermentUnit;
        //   total_deferment_count = total_deferment_count + this.padWells[x].DefermentValue
        // @ts-ignore
        total_deferment_count = parseFloat(total_deferment_count) + parseFloat(tasq.Wells[x].Payload.MCF);
      }
    }
  }

  return +(total_deferment_count).toFixed(0);
};

const getOverriddenManualTasqName = (tasq: TasqJobResponse) => {
  const jobType = [
    {
      key: 'Contractor Jobs',
      value: 'Contractor Jobs',
      id: 0,
      type: 'job',
    },
    {
      key: 'Drain Pots and Drips',
      value: 'Drain Pots and Drips',
      id: 1,
      type: 'job',
    },
    {
      key: 'Drain Tank Bottoms',
      value: 'Drain Tank Bottoms',
      id: 2,
      type: 'job',
    },
    {
      key: 'Dump Checks',
      value: 'Dump Checks',
      id: 3,
      type: 'job',
    },
    {
      key: 'Equipment Inspection',
      value: 'Equipment Inspection',
      id: 4,
      type: 'job',
    },
    {
      key: 'Facility Walkdown',
      value: 'Facility Walkdown',
      id: 5,
      type: 'job',
    },
    {
      key: 'Facility Winterization',
      value: 'Facility Winterization',
      id: 6,
      type: 'job',
    },
    {
      key: 'Gauge',
      value: 'Gauge',
      id: 7,
      type: 'job',
    },
    {
      key: 'HSE',
      value: 'HSE',
      id: 8,
      type: 'job',
    },
    {
      key: 'Hot Oiling',
      value: 'Hot Oiling',
      id: 9,
      type: 'job',
    },
    {
      key: 'Install Artificial Lift',
      value: 'Install Artificial Lift',
      id: 10,
      type: 'job',
    },
    {
      key: 'Install Compressor',
      value: 'Install Compressor',
      id: 11,
      type: 'job',
    },
    {
      key: 'LOTO',
      value: 'LOTO',
      id: 12,
      type: 'job',
    },
    {
      key: 'Preventative Maintenance',
      value: 'Preventative Maintenance',
      id: 13,
      type: 'job',
    },
    {
      key: 'Regain Supply Gas',
      value: 'Regain Supply Gas',
      id: 14,
      type: 'job',
    },
    {
      key: 'Regulatory',
      value: 'Regulatory',
      id: 15,
      type: 'job',
    },
    {
      key: 'Replace Bad Battery',
      value: 'Replace Bad Battery',
      id: 16,
      type: 'job',
    },
    {
      key: 'Schedule Work',
      value: 'Schedule Work',
      id: 17,
      type: 'job',
    },
    {
      key: 'Site Check',
      value: 'Site Check',
      id: 18,
      type: 'job',
    },
    {
      key: 'Thaw Equipment',
      value: 'Thaw Equipment',
      id: 19,
      type: 'job',
    },
    {
      key: 'Unknown Well Issue',
      value: 'Unknown Well Issue',
      id: 20,
      type: 'job',
    },
    {
      key: 'Plunger Change',
      value: 'Plunger Change',
      id: 21,
      type: 'recommendation',
    },
    {
      key: 'Standing Valve',
      value: 'Standing Valve',
      id: 22,
      type: 'recommendation',
    },
  ];
  const findJob = jobType.find((job) => job.value === tasq.PredictionType);
  if (findJob) {
    if (findJob.key === 'Preventive Maintenance') {
      return 'PM';
    }
    return findJob.key;
  }
  return tasq.PredictionType;
};


const getSnoozedForTodayStatus = (tasq: any) => {
  if (tasq.Snoozed && tasq.Snoozed.WaitUntilReason && tasq.Snoozed.WaitUntilReason.includes('Snoozed') && tasq.Snoozed.UntilDate && new Date(tasq.Snoozed.UntilDate).getTime() > new Date().getTime()) {
    return true;
  }

  return false;
};

const mapDefaultTasq = (t, userEmail = '', mapWaitingOn = true): TasqJob => {
  let wellName = t.wellName != null ? t.wellName : t.NodeID === 'Example Job' ? 'Braum Family 9-4-1XH' : t.NodeID;
  if (t.wellName != null) {
    wellName = t.wellName;
  } else {
    wellName = t.NodeID;
    if (t.NodeID.wellName != null) {
      wellName = t.NodeID.wellName;
    }
  }

  if(mapWaitingOn){
    let wellStatus = (workflowModule.statusByWellName(wellName))
    t.Snoozed = wellStatus ? wellStatus.snoozed : null;
  }


  //snoozed end date is 3 days back from now then remove snooze
  if (t.Snoozed && t.Snoozed.UntilDate) {
    // @ts-ignore
    const endDate = DateTime.fromISO(t.Snoozed.UntilDate);
    let daysDiff: any = DateTime.local().diff(endDate, 'days').toObject()?.days?.toFixed();
    if (daysDiff > 3) {
      t.Snoozed = null;
    }
  }

	var initialUserName = t.Assignee != null ? t.Assignee.initial_assignment : '' // eslint-disable-line
	var assignee = t.Assignee != null ? t.Assignee : t.Username // eslint-disable-line
  let username = t.Username != null ? t.Username.toLowerCase() : t.Username;
  if (t.ID != null) {
    for (var x = 0; x < t.Columns.length; x++) {
      if (t.Columns[x].ColumnType == 'USER' && t.Columns[x].CoreField) {
        username = t.Columns[x].Response;
      }
    }
  }

  const tasq = {
    id: t.PredictionID || t.ID || t.NodeID || t.wellName,
    scheduledJobID: t.ID != null ? t.ID : null,
    predictionType: t.PredictionType != null ? t.PredictionType : t.ID != null ? 'Scheduled Job' : 'Producing',
    engineerType: t.PredictionType != null ? ucfirst(t.PredictionType === 'Off-Target RT' ? 'Real-Time' : t.PredictionType || '') : t.ID != null ? 'Scheduled Job' : 'Producing',
    wellName,
    padName: t.PadName,
    area: t.Area,
    date: t?.StateChangeDate,
    stateChangeStart: t.StateChangeDate,
    route: t.Route,
    stateChangeEnd: new Date(),
    predictionsList: t.Predictions,
    time: t.time,
    lastResponseTime: t.LastResponseTime ? t.LastResponseTime : [],
    lastResponseData: t.LastResponseData ? t.LastResponseData : [],
    stateChangeDate: t.StateChangeDate,
    scheduledJobStartDate: t['Start Date'] != null ? new Date(t['Start Date']) : null,
    scheduledJobDueDate: t['Due Date'] != null ? new Date(t['Due Date']) : null,
    responseData: t.ResponseData,
    viewed: t.Viewed,
    snoozed: t.Snoozed,
    waitUntilReason: t.Snoozed ? t.Snoozed.WaitUntilReason : '',
    isSnoozedForToday: getSnoozedForTodayStatus(t),
    waitingOnEndDate: t.Snoozed ? t.Snoozed.UntilDate : '',
    waitingOnStartDate: t.Snoozed ? t.Snoozed.WaitStartDate : '',
    rtCauseSignal: (t?.Payload != null && t?.Payload.Signal != null) ? t?.Payload.Signal : null,
    highLP: (t?.Payload != null && t?.Payload.HighLP != null && t?.Payload.HighLP) ? 'Line Pressure' : null,
    isViewed: (t.Viewed || []).includes(userEmail),
    assignee,
    isLocked: t.locked && (t.locked || {}).Status,
    isRepetitive: t.repetitive,
    isSnoozed: t.snoozed,
    isRejected: t.rejected,
    noComms: t.PredictionType === TASQ_NO_COMMS_TYPE,
    isWorkflowGen: t.WorkflowDetailsID || false,
    workflowTasqId: t.WorkflowTaskID,
    workflowDetailsID: null,
    setpointRecord: {},
    isGoodCatch: t.GoodCatch,
    username: username, // eslint-disable-line
    reassignedUserName: t.Assignee != null && t.Assignee.reassignment != null ? t.Assignee.reassignment.new_assignee || '' : '', // eslint-disable-line
	initialUserName: initialUserName, // eslint-disable-line
    isManuallyAdded: t.ManuallyAssigned,
    offTargetStartDate: t.OffTargetStartDate,
    isWatched: t?.Watchlist?.includes(userEmail),
    assignmentTime: t?.AssignmentTime,
    completed: t.Closed && t.Closed == 'True',
    completedForToday: t.CompletedForToday != null ? (new Date(`${t.CompletedForToday}Z`).setHours(0, 0, 0, 0) == (new Date()).setHours(0, 0, 0, 0)) : false,
    defermentValue: t?.DefermentValue,
    defermentType: (t?.Payload != null && t?.Payload.DefermentType != null) ? t?.Payload.DefermentType : 'gas_rate',
    // @ts-ignore
    defermentPercentage: (t?.Payload != null && t?.Payload.DefermentPerc != null) ? +parseFloat(t?.Payload.DefermentPerc * 100).toFixed(0) : null,
    defermentUnit: (t?.Payload != null && t?.Payload.Units != null) ? t?.Payload.Units : 'mcf',
    deferringDays: (t?.Payload != null && t?.Payload.DeferringDays != null) ? t?.Payload.DeferringDays : null,
    outcome: (t?.Payload != null && t?.Payload.Outcome != null) ? t?.Payload.Outcome : null,
    comments: t.comments,
    wells: [],
    overriddenName: t.ManuallyAssigned ? getOverriddenManualTasqName(t) : getOverriddenTasqName(t),
    stepOneCompleted: false,
    stepTwoCompleted: false,
    validationResponse: t.ValidationResponse || {},
    formsResponse: typeof t.FormsResponse === 'object' && t.FormsResponse && t.FormsResponse.length ? t.FormsResponse :  [],
    stepThreeCompleted: false,
    stepFourCompleted: false,
    respondedToday: false,
    respondedOneDayAgo: false,
    team: t.Team,
    respondedTwoDaysAgo: false,
    padDeferment: getTotalPadDeferment(t),
    respondedThreeDaysAgo: false,
    respondedFourDaysAgo: false,
    level: t.Level != null ? t.Level : t.level != null ? t.level : 'WELL',
    stepLabel: '',
    scheduledJobStatus: t.Status != null ? t.Status.Status : null,
    scheduledJobColumns: t.Columns != null ? t.Columns : null,
    dateAgoText: '',
    wellTestData: null,
    lastActionTime: 0,
    views: t.ViewsCount ? t.ViewsCount : {},
    actions: t.ActionsCount ? t.ActionsCount : {},
    setpointAccepted: t.Accepted,
    tasqType: t.TasqType,
    creationComment: t.CreationComment,
    createdBy: t.CreatedBy
  };

  if (t.WorkflowDetailsID != null) {
    tasq.workflowDetailsID = t.WorkflowDetailsID;
  }
  if (t.Payload != null && t.PredictionType != null && (t.PredictionType.toLowerCase() == 'anomaly' || t.PredictionType.toLowerCase() == 'state change')) {
    tasq.stateChangeStart = t.Payload.Record.StateChangeDate;
    tasq.stateChangeEnd = new Date(); // t.Payload.Record.StateChangeDateEnd
    tasq.stateChangeDate = t.Payload.Record.StateChangeDate;
    tasq.date = t.Payload.Record.StateChangeDate;
  } else if (t.PredictionType != null && t.PredictionType.toLowerCase() == 'off-target' && t.Payload.OffTargetStartDate) {
    tasq.offTargetStartDate = t.Payload.OffTargetStartDate.replaceAll(' ', 'T');
    tasq.defermentValue = t.Payload.MCF;
  } else if (t.PredictionType != null && t.PredictionType.toLowerCase() == 'setpoint' && t.Payload.OffTargetStartDate) {
    tasq.offTargetStartDate = t.Payload.OffTargetStartDate.replaceAll(' ', 'T');
    tasq.defermentValue = t.Payload.MCF;
  } else if (t.PredictionType != null && t.PredictionType.toLowerCase() == 'off-target rt' && t.Payload.OffTargetStartDate) {
    tasq.offTargetStartDate = t.Payload.OffTargetStartDate.replaceAll(' ', 'T');
    const updatedDate = DateTime.fromISO(t.Payload.OffTargetStartDate, { zone: 'America/Denver' });
    // @ts-ignore
    tasq.rtStartDate = updatedDate;
    tasq.defermentValue = t.Payload.MCF;
  }

  if(t.Payload && t.Payload.Record){
    tasq.setpointRecord = t.Payload.Record
  }

  if (t.Wells != null) {
	  tasq.wells = t.Wells;
  } else if (t.wells != null) {
    tasq.wells = t.wells;
  }

  if (tasq.lastResponseTime && tasq.lastResponseTime.length) {
    // eslint-disable-next-line prefer-destructuring
    tasq.lastActionTime = new Date(tasq.lastResponseTime.at(-1)).getTime();
  }

  const todaysDate = new Date();
  const oneDayAgo = new Date().setDate(new Date().getDate() - 1);
  const twoDayAgo = new Date().setDate(new Date().getDate() - 2);
  const threeDayAgo = new Date().setDate(new Date().getDate() - 3);
  const fourDayAgo = new Date().setDate(new Date().getDate() - 4);
  for (var x = 0; x < tasq.lastResponseTime.length; x++) {
    const movedOutside = `${tasq.lastResponseTime[x]}.000Z`;
    const lastResponseLocalTime = new Date(movedOutside);
    // call setHours to take the time out of the comparison
    if (lastResponseLocalTime.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) {
      // Date equals today's date
      tasq.respondedToday = true;
    }
    if (lastResponseLocalTime.setHours(0, 0, 0, 0) == new Date(oneDayAgo).setHours(0, 0, 0, 0)) {
      // Date equals today's date
      tasq.respondedOneDayAgo = true;
    }
    if (lastResponseLocalTime.setHours(0, 0, 0, 0) == new Date(twoDayAgo).setHours(0, 0, 0, 0)) {
      // Date equals today's date
      tasq.respondedTwoDaysAgo = true;
    }
    if (lastResponseLocalTime.setHours(0, 0, 0, 0) == new Date(threeDayAgo).setHours(0, 0, 0, 0)) {
      // Date equals today's date
      tasq.respondedThreeDaysAgo = true;
    }
    if (lastResponseLocalTime.setHours(0, 0, 0, 0) == new Date(fourDayAgo).setHours(0, 0, 0, 0)) {
      // Date equals today's date
      tasq.respondedFourDaysAgo = true;
    }
  }

  // @ts-ignore
  tasq.stepOneCompleted = getStepOneCompleted(tasq);
  // @ts-ignore
  tasq.stepTwoCompleted = getStepTwoCompleted(tasq);
  // @ts-ignore
  tasq.stepThreeCompleted = getStepThreeCompleted(tasq);
  // @ts-ignore
  tasq.stepFourCompleted = getStepFourCompleted(tasq);
  // @ts-ignore
  tasq.stepLabel = getStepLabel(tasq);
  // @ts-ignore
  tasq.dateAgoText = getDateAgoText(tasq).text;
  // @ts-ignore
  tasq.dateAgoValue = getDateAgoText(tasq).value;
  // @ts-ignore

  if (t.TaskID != null && t.ClusterID != null && t.GanttChartID != null) {
	  // @ts-ignore
    tasq.wellTestData = {
      // @ts-ignore
      taskID: t.TaskID,
      // @ts-ignore
      clusterID: t.ClusterID,
      // @ts-ignore
      ganttChartID: t.GanttChartID,
    };
  }

  // @ts-ignore
  return tasq;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  mapDefaultTasq,
};
