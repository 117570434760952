import { mapTasqResponse } from '@/utils/tasqResponses';
import store from '@/store';
import Vue from 'vue'
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { mapDefaultTasq } from '@/utils/tasqs';

import { mapDefaultProducingTasq } from '@/utils/producingTasqs';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import predictionsApolloClient from '@/lib/appsync/workflow';
import getTasqsLists from '@/graphql/predictions/mutations/getTasqsLists.graphql';
import searchTasqs from '@/graphql/predictions/mutations/searchTasqsList.graphql';
import getMyWaitList from '@/graphql/workflow/queries/getMyWaitList.graphql';
import getTasq from '@/graphql/workflow/queries/getTasq.graphql';
import getTasqEventHistory from '@/graphql/workflow/queries/getTasqEventHistory.graphql';
import getAllTasqResponses from '@/graphql/workflow/queries/getAllTasqResponses.graphql';
import { Debounce } from 'vue-debounce-decorator';
import { getConfigEnv, jsonParse, sleep } from '@/utils/helpers';
import GetTasqsListsResponse from '@/graphql/predictions/interfaces/GetTasqsListsResponse';
import SearchTasqsResponse from '@/graphql/predictions/interfaces/SearchTasqsListsResponse';
import TasqJobResponse from '@/graphql/predictions/interfaces/TasqJobResponse';
import accountModule from '@/store/modules/accountModule';
import workflowModule from '@/store/modules/workflowModule';
import workflowApolloClient from '@/lib/appsync/workflow';
import userPreferenceDB from '@/lib/userPreference';
import getSubmittedForms from '@/graphql/workflow/queries/getSubmittedForms.graphql';
import localForage from '@/lib/localForage';
import workspaceExample from '@/router/routes/workspaceExample';
import isOnline from 'is-online';
import { mapDefaultWorkTicketTasqs } from '@/utils/workTicketTasqs';
import getWorkTickets from '@/graphql/workflow/queries/getWorkTicketTasqs.graphql';
import getCountTasqForJob from '@/graphql/workflow/queries/getCountOpenTasqsForJob.graphql';
import getCountTasqForAsset from '@/graphql/workflow/queries/getCountOpenTasqsForAsset.graphql';
import getGoodCatchDefermentForTime from '@/graphql/workflow/queries/getGoodCatchDefermentForTime.graphql';
import getDailyWorkTicketData from '@/graphql/workflow/queries/getDailyWorkTicketData.graphql';
import updateTasqViewsCountMutation from '@/graphql/workflow/mutations/updateTasqViewsCountMutation.graphql';
import updateTasqMutation from '@/graphql/workflow/mutations/updateTasqMutation.graphql';
import workspaceModule from './workspaceModule';
import { VERTICAL_LIST_SORT_DETAULT_VALUE, VERTICAL_LIST_DEFAULT_LEVEL } from '@/lib/constants';
import router from '@/router'

@Module({
  dynamic: true,
  namespaced: true,
  name: 'tasqsListModule',
  store,
})
class TasqsListModule extends VuexModule {
  tasqEventHistoryList: any[] = [];

  completedTasqList: TasqJob[] = [];

  waitingTasqList: TasqJob[] = [];

  tasqList: TasqJob[] = [];

  isVerticalListUnPinned = false;

  isVerticalListHovering = false;

  allTasqs: TasqJob[]| any = [];

  padNestedTasqs: TasqJob[] = [];

  producingWells: TasqJob[] = [];

  additionalPadNestedTasqs: TasqJob[] = [];

  activeTasq: TasqJob|null|undefined = null;

  activeWorkTicket: TasqJob|null|undefined = null;

  activeOfflineTasq: TasqJob|null|undefined = null;

  activeProducingTasq: TasqJob|null|undefined = null;

  activePage = '';

  kanbanSelectedTasq: any = '';

  showTasqDetailsMobile = false;

  activeFilterRoute: any = [];

  activeFilterWaitingOn: any = [];

  isLoadingTasqResponses = false

  isLoadingProducingData = false

  activeSubstringFilter = '';

  activeListType = '';

  tasqListLevel = VERTICAL_LIST_DEFAULT_LEVEL;

  activeFilterList: any = null;

  signalChartTime = 60;

  isLoadingTasqs = false

  isLoadingPaginatedTasqs = false

  isLoadingSearchTasqs = false

  activeTasqScroll: any = null;

  tasqStartLimit: any = 0;

  tasqEndLimit: any = 30;

  progressPercentage: any = 0;

  savingForOffline = false;


  isNotificationListOpen = false;

  isSidebarLoadedFirstTime = false;

  defermentDays: any = 0;

  signalChartTimeObj: any = null;

  cycleTimeLessThanFilter: any = null;

  unitsGreaterThanFilter: any = null;

  unitsLessThanFilter: any = null;

  cycleTimeGreaterThanFilter: any = null;

  defermentPercentLessThanFilter: any = null;

  defermentPercentGreaterThanFilter: any = null;

  cycleTimeDays: any = 0;

  jobTypeFilter: any = [];

  tasqLimitPerPage: any = 30;

  tasqTotalPages: any = 1;

  usernamesList: any = [];

  paginationLoading: any = true;

  batchResponseEnabled: any = false;

  fromNowBack = 0;

  activeFilterTeam = [];

  activeFilterArea = [];

  isBatchResponding: boolean = false

  batchResponseDisabledMsg = 'Please select at least 1 tasq';

  currentTasqListPageNo: any = 1;

  todaysTasqLength: any = 0;

  infiniteScrollHeight: any = 0;

  activePredictionTypeFilter: any = []

  allPredictionTypes: any = []

  allPredictionDistTypes: any = []

  tasqResponseResults: any = []

  checkedTasqs: any = []

  isEditing: boolean = false

  isCalendarView: boolean = false

  isBatchReassign: boolean = false;

  isActionUnReleated = false;

  enablePreloading: boolean = false;

  chartFromTime: any = ''

  chartToTime: any = ''

  submittedTasqs: any = []

  pendingTasqs: any = []

  pendingTasqsPromise: any = []

  isInitialPageLoad = true

  isHistorySectionOpen = false;

  textSearchValue: string = ''
  isMobileView: boolean = false
  activeFilters: any = {}
  emptyFilter: any = {
    id: '',
    name: '',
    settings: {}
  }

  get activeFilterByPage () {
    return (pageName: string = '') => {
      if (!pageName) {
        // @ts-ignore
        pageName = router.history.current.name
      }
      const filter = this.activeFilters[pageName]
      if (!filter) {
        return this.emptyFilter
      }
      return filter
    }
  }


  @Mutation
  toggleNotificationList(): void {
    this.isNotificationListOpen = !this.isNotificationListOpen;
  }

  @Mutation
  setIsSidebarLoadedFirstTime(value): void {
    this.isSidebarLoadedFirstTime = value;
  }

  @Mutation
  setTextSearchValue(value): void {
    this.textSearchValue = value;
  }

  @Mutation
  setIsMobileView(value): void {
    this.isMobileView = value;
  }

  @Mutation
  setIsVerticalListUnPinned(val): void {
    this.isVerticalListUnPinned = val;
  }

  @Mutation
  setIsVerticalListHovering(val): void {
    this.isVerticalListHovering = val;
  }

  @Mutation
  setIsInitialPageLoad(val): void {
    this.isInitialPageLoad = val;
  }

  @Mutation
  setIsHistorySectionOpen(state): void {
    this.isHistorySectionOpen = state;
  }

  @Mutation
  setIsEditing(val): void {
    this.isEditing = val;
  }

  @Mutation
  pushSubmittedTasq(tasq): void {
    this.submittedTasqs.push(tasq);
  }

  @Mutation
  async pushPendingTasq(tasq) {
    this.pendingTasqs.push(tasq);
  }

  @Mutation
  setPendingTasq(data): void {
    this.pendingTasqs = data;
  }

  @Mutation
  async pushPendingTasqPromise(tasq) {
    this.pendingTasqsPromise.push(tasq);
    await window.localStorage.setItem('offline-pending-tasqs', JSON.stringify(this.pendingTasqsPromise));
  }

  @Mutation
  setPendingTasqPromise(data): void {
    this.pendingTasqsPromise = data;
  }

  @Mutation
  setCalendarView(val): void {
    this.isCalendarView = val;
  }

  @Mutation
  setIsBatchResponding(val): void {
    this.isBatchResponding = val;
  }

  @Mutation
  setIsBatchReassign(val): void {
    this.isBatchReassign = val;
  }

  @Mutation
  setIsActionUnReleated(val): void {
    this.isActionUnReleated = val;
  }

  @Mutation
  setEablePreloading(value): void {
    this.enablePreloading = value;
  }

  @Mutation
  setTasqEventHistoryList(event_list): void {
    const event_list_results: any[] = [];
    for (let x = 0; x < event_list.length; x++) {
      if (JSON.parse(event_list[x]).type !== 'JOB_LABELED') {
        event_list_results.push(JSON.parse(event_list[x]));
      }
    }
    event_list_results.sort((a, b) => b.time.localeCompare(a.time));
    this.tasqEventHistoryList = event_list_results;
  }

  @Mutation
  setBatchResponseEnabled(): void {
    const checkedFullTasqResponses: any[] = [];
    const allTasqs = [...this.tasqList, ...this.producingWells, ...this.completedTasqList, ...this.waitingTasqList, ...this.padNestedTasqs, ...this.additionalPadNestedTasqs];
    for (var x = 0; x < this.checkedTasqs.length; x++) {
      // eslint-disable-next-line no-loop-func
      const checkedTasq = allTasqs.find((t) => t.id.toLowerCase() === this.checkedTasqs[x].toLowerCase());
      let didAddResponseData = false;
      for (let y = 0; y < this.tasqResponseResults.length; y++) {
        if (this.checkedTasqs[x] == this.tasqResponseResults[y].PredictionID) {
          // @ts-ignore
        checkedTasq!.ResponseData = this.tasqResponseResults[y].ResponseData;
        didAddResponseData = true;
        break;
        }
      }
      if (!didAddResponseData && checkedTasq) {
        // @ts-ignore
        checkedTasq.ResponseData = { UserResponse: {} };
      }

      checkedFullTasqResponses.push(checkedTasq);
    }

    let predictionType = null;
    let responseData = null;
    for (var x = 0; x < checkedFullTasqResponses.length; x++) {
      if (checkedFullTasqResponses[x].predictionType == 'Producing') {
        this.batchResponseEnabled = false;
        this.batchResponseDisabledMsg = 'Cannot respond to type Producing.';
      }
      if (predictionType == null) {
        predictionType = checkedFullTasqResponses[x].predictionType;
        if (checkedFullTasqResponses[x].ResponseData) {
          responseData = checkedFullTasqResponses[x].ResponseData.UserResponse;
        }
      } else {
        if (checkedFullTasqResponses[x].predictionType != predictionType) {
          this.batchResponseEnabled = false;
          this.batchResponseDisabledMsg = 'Tasq types must match to perform batch responses.';
          return;
        }
        if (checkedFullTasqResponses[x].ResponseData && JSON.stringify(checkedFullTasqResponses[x].ResponseData.UserResponse) != JSON.stringify(responseData)) {
          this.batchResponseEnabled = false;
          this.batchResponseDisabledMsg = 'Existing responses for the selected tasqs do not match.';
          return;
        }
      }
    }
    this.batchResponseEnabled = true;
    this.batchResponseDisabledMsg = '';
  }

  @Mutation
  checkTasq(tasqId): void {
    if (this.checkedTasqs.includes(tasqId)) {
      const index = this.checkedTasqs.indexOf(tasqId);
      if (index > -1) {
        this.checkedTasqs.splice(index, 1);
      }
    } else {
      this.checkedTasqs.push(tasqId);
    }
  }

  @Mutation
  updateTasqDetails(tasq): void {
    let type  ='allTasqs'
    if(tasq.engineerType === 'Producing' && tasq.level !== 'PAD'){
      type = 'producingWells'
    }

    const index = this[type].findIndex(
      tasqObject => tasqObject.id == tasq.id
    );

    if (index > -1) {
      let oldTasq = this[type][index]
      Object.keys(oldTasq).forEach(key => oldTasq[key] = null);
      Object.entries(tasq).forEach(entry => Vue.set(oldTasq, entry[0], entry[1]));
    }
  }

  @Mutation
  updateWorkTicketDetails(workTicket): void {
    const index = this.workTickets.findIndex((ticket) => ticket.id === workTicket.id);
    if (index > -1) {
      // this.isLoadingTasqs = true;
      const localWorkTicket = workTicket;
      localWorkTicket.priorityLevel = [
        'Lowest',
        'Low',
        'Medium',
        'High',
        'Highest',
      ].findIndex((p) => p === localWorkTicket.priority);
      this.workTickets[index] = localWorkTicket;
      this.workTickets.push(localWorkTicket);
      this.workTickets.pop();
    }
  }

  @Mutation
  resetCheckedTasqs(): void {
    this.checkedTasqs = [];
  }

  @Mutation
  setTasqResponseResults(data): void {
    this.tasqResponseResults = data;
  }

  @Mutation
  setSignalChartTime(data): void {
    this.signalChartTime = data;
  }

  @Mutation
  setFromNowBack(data): void {
    this.fromNowBack = data;
  }

  @Mutation
  setPadNestedTasqs(data) {
    this.padNestedTasqs = data;
  }

  @Mutation
  setIsLoadingTasqResponses(data) {
    this.isLoadingTasqResponses = data;
  }

  @Mutation
  setKanbanSelectedTasq(tasqId): void {
    this.kanbanSelectedTasq = tasqId;
  }

  @Mutation
  setAdditionalPadNestedTasqs(data) {
    this.additionalPadNestedTasqs = data;
  }

  @Mutation
  updateAdditionalPadNestedTasqs(data) {
    this.additionalPadNestedTasqs = this.additionalPadNestedTasqs.concat(data);
  }

  @Mutation
  setTasqList({ data, listType = 'tasqList', concat = false }: {
    data: TasqJob[],
    listType?: string,
    concat?: boolean,
  }): void {
    if (concat) {
      this[listType] = this[listType].concat(data);
    } else {
      this[listType] = data;
    }
    this.allTasqs = this.allTasqs.concat(data);
  }

  @Mutation
  setProducingWells(data): void {
    this.producingWells = data;
  }

  @Mutation
  setSubmittedTasqs(data): void {
    this.submittedTasqs = data;
  }

  @Mutation
  setPaginationLoading(value): void {
    this.paginationLoading = value;
  }

  @Mutation
  setChartFromTime(value): void {
    // eslint-disable-next-line prefer-destructuring
    this.chartFromTime = value.split('T')[0];
  }

  @Mutation
  setChartToTime(value): void {
    // eslint-disable-next-line prefer-destructuring
    this.chartToTime = value.split('T')[0];
  }

  @Mutation
  setShowTasqDetailsMobile(value): void {
    this.showTasqDetailsMobile = value;
  }

  @Mutation
  setsaveOffline(value): void {
    this.savingForOffline = value;
  }

  @Mutation
  updateTasqInList({ data, listType = 'tasqList' }: {
    data: TasqJob,
    listType?: string,
  }): void {
    if (listType === 'tasqList') {
      if (data.id) {
        let completedTasq = false;
        if (this.tasqListLevel !== 'Pad') {
          const foundIndexInTasqList = this[listType].findIndex(((obj, k) => obj.id === data.id));
          if (foundIndexInTasqList > -1) {
              this[listType].splice(foundIndexInTasqList, 1);

              const foundIndexInCompleteList = this.completedTasqList.findIndex(((obj, k) => obj.id === data.id));

              if (foundIndexInCompleteList > -1) {
                this.completedTasqList[foundIndexInCompleteList] = data;
              } else {
                this.completedTasqList.push(data);
              }

          } else {
            this[listType].push(data);
          }

          const foundIndexInAllTasqList = this.allTasqs.findIndex(((obj, k) => obj.id === data.id));
          if (foundIndexInAllTasqList > -1) {
            this.allTasqs[foundIndexInAllTasqList] = data;
          } else {
            this.allTasqs.push(data);
          }
        } else {
          const foundIndexInPadList = this[listType].findIndex(((obj, k) => obj.padName === data.padName));
          if (foundIndexInPadList > -1) {
            const data3 = this[listType][foundIndexInPadList].wells;
            if (data3) {
              const found = data3.findIndex((well) => well.id === data.id);
              if (found > -1) {
                if (!completedTasq) {
                  this[listType][foundIndexInPadList].wells.splice(found, 1);
                  this[listType][foundIndexInPadList].wells.push(data);
                } else {
                  this[listType][foundIndexInPadList].wells.splice(found, 1);
                  // eslint-disable-next-line no-unused-expressions
                  const newDataPayload = data;
                  newDataPayload.predictionType = 'Producing';
                  newDataPayload.overriddenName = 'Producing';
                  this[listType][foundIndexInPadList].wells.push(newDataPayload);

                  const foundIndexInCompleteList = this.completedTasqList.findIndex(((obj, k) => obj.id === data.id));

                  if (foundIndexInCompleteList > -1) {
                    this.completedTasqList[foundIndexInCompleteList] = data;
                  } else {
                    this.completedTasqList.push(data);
                  }
                }
              }
            }
          }
        }

        setTimeout(() => {
          this.isLoadingTasqs = false;
        }, 150);
      }
    }
  }

  @Mutation
  updateWorkTicketInList(data): void {
    if (data.id) {
      this.isLoadingTasqs = true;
      const foundIndexInWorkTickets = this.workTickets.findIndex(((obj, k) => obj.id === data.id));
      if (foundIndexInWorkTickets > -1) {
        this.workTickets.splice(foundIndexInWorkTickets, 1);
        this.workTickets.push(data);
      } else {
        this.workTickets.push(data);
      }
      setTimeout(() => {
        this.isLoadingTasqs = false;
      }, 150);
    }
  }

  @Mutation
  setUsernamesList(username) {
    if (username) {
      if (!this.usernamesList.includes(username)) {
        this.usernamesList.push(username);
      } else {
        const index = this.usernamesList.indexOf(username);
        if (index > -1) {
          this.usernamesList.splice(index, 1);
        }
      }
    }
  }

  @Mutation
  setUsernamesBulkList(usernames) {
    this.usernamesList = usernames;
  }

  @Mutation
  setTasqListLevel(level): void {
    this.tasqListLevel = level;
  }

  @Mutation
  setProgresPercentage(data): void {
    if (this.progressPercentage + data < 100) {
      this.progressPercentage += data;
    }

    if (this.progressPercentage >= 89) {
      setTimeout(() => {
        this.progressPercentage = 95;
      }, 5000);
      setTimeout(() => {
        this.progressPercentage = 0;
      }, 10000);
    }
  }

  @Mutation
  addProgresPercentage(data): void {
    this.progressPercentage = data;
  }

  @Mutation
  setWaitingTasqs(data): void {
    this.waitingTasqList = data;
  }


  tasqDataLoadingFeedback = false;

  @Mutation
  setTasqDataLoadingFeedback(data): void {
    this.tasqDataLoadingFeedback = data;
  }

  @Mutation
  setActiveTasq(id: string): void {
    this.activeWorkTicket = null;
    let activeTasqDiv: any = null;

    activeTasqDiv = document.getElementById(id)!;
    if (activeTasqDiv && activeTasqDiv.previousSibling) {
      this.activeTasqScroll = activeTasqDiv.previousSibling.id;
    } else if (activeTasqDiv && activeTasqDiv.nextSibling) {
      this.activeTasqScroll = activeTasqDiv.nextSibling.id;
    }
    if (this.activeTasqScroll) {
      localStorage.setItem('ActiveTasqPageNo', this.currentTasqListPageNo);
      localStorage.setItem('ActiveTasqScroll', this.activeTasqScroll);
    }
    this.activeTasq = this.allTasqs.find((i) => i.id === id);

    if (!this.activeTasq) {
      this.activeTasq = this.waitingTasqList.find((i) => i.id === id);
    }
    if (!this.activeTasq) {
      this.activeTasq = [...workspaceModule.totalItems, ...this.tasqList, ...this.completedTasqList, ...this.waitingTasqList, ...this.padNestedTasqs, ...this.additionalPadNestedTasqs, ...this.producingWells].find((t) => id && t.id.toLowerCase() === id.toLowerCase());
    }
  }

  @Mutation
  setActiveWorkTicket(ticket): void {
    this.activeWorkTicket = ticket;
    this.activeTasq = ticket;
  }

  @Mutation
  setActiveTasqExplicit(tasq): void {
    this.activeTasq = tasq;
  }

  @Mutation
  setActiveFilterList(list: any): void {
    this.activeFilterList = list;
  }

  @Mutation
  setSubstringFilter(searchQuery: any): void {
    this.activeSubstringFilter = searchQuery;
  }

  @Mutation
  setPredictionTypeFilter(predictionTypes: any): void {
    this.activePredictionTypeFilter = predictionTypes;
  }

  @Mutation
  setDefermentDaysFilter(defermentDays: any): void {
    this.defermentDays = defermentDays;
  }

  @Mutation
  setCycleTimeDaysFilter(cycleTimeDays: any): void {
    this.cycleTimeDays = cycleTimeDays;
  }

  @Mutation
  setJobTypeFilter(jobTypeFilter: any): void {
    this.jobTypeFilter = jobTypeFilter;
  }

  @Mutation
  setAllPredictionTypes(predictionTypes: any): void {
    // eslint-disable-next-line no-param-reassign
    predictionTypes = predictionTypes.filter((e) => e !== 'state change' && e !== 'Label' && e !== 'Well down');
    predictionTypes.push('Off-Target Line Pressure');
    predictionTypes.sort();
    this.allPredictionTypes = predictionTypes;
  }

  @Mutation
  setAllPredictionDistTypes(predictionTypes: any) {
    // eslint-disable-next-line no-param-reassign
    const updatedPredictionTypes = Object.keys(predictionTypes).filter((key) => predictionTypes[key]);
    updatedPredictionTypes.push('Off-Target Line Pressure');
    updatedPredictionTypes.sort();
    userPreferenceDB.setItem('allPredictionTypes', updatedPredictionTypes);
    this.allPredictionTypes = updatedPredictionTypes;
  }

  @Mutation
  setInfiniteListScroll(scrollHeight: any): void {
    this.infiniteScrollHeight = scrollHeight;
  }

  @Mutation
  setTasqStartLimit(limit: any = 0): void {
    if (limit === 0) {
      this.tasqList = [];
      this.allTasqs = [];
    }
    this.tasqStartLimit = limit;
  }

  @Mutation
  resetPagination(): void {
    this.tasqList = [];
    this.allTasqs = [];
    this.tasqStartLimit = 0;
    this.tasqEndLimit = 30;
    this.tasqLimitPerPage = 30;
    this.tasqTotalPages = 1;
    this.currentTasqListPageNo = 1;
    this.todaysTasqLength = 0;
  }

  @Mutation
  setTotalTasqPagesLength(todaysTasqLength): void {
    this.tasqTotalPages = Math.ceil((todaysTasqLength) / this.tasqLimitPerPage);
    this.todaysTasqLength = todaysTasqLength;
  }

  @Mutation
  @Debounce(5000)
  updatePaginationDetails(): void {
    this.currentTasqListPageNo += 1;
    this.tasqStartLimit = this.tasqEndLimit + 1;
    this.tasqEndLimit = this.tasqStartLimit + this.tasqLimitPerPage;
  }

  @Mutation
  setActiveAreaFilter(areas): void {
    this.activeFilterArea = areas;
  }

  @Mutation
  setActiveTeamFilter(teams): void {
    this.activeFilterTeam = teams;
  }

  @Mutation
  setCycleTimeLessThanFilter(value): void {
    this.cycleTimeLessThanFilter = value;
  }

  @Mutation
  setCycleTimeGreaterThanFilter(value): void {
    this.cycleTimeGreaterThanFilter = value;
  }

  @Mutation
  setDefermentPercentLessThanFilter(value): void {
    this.defermentPercentLessThanFilter = value;
  }

  @Mutation
  setDefermentPercentGreaterThanFilter(value): void {
    this.defermentPercentGreaterThanFilter = value;
  }

  @Mutation
  setActiveRouteFilter(routes): void {
    this.activeFilterRoute = routes;
  }

  @Mutation
  setActiveWaitingOnFilter(waitingOn): void {
    this.activeFilterWaitingOn = waitingOn;
  }

  @Mutation
  setUnitsLessThanFilter(value): void {
    this.unitsLessThanFilter = value;
  }

  @Mutation
  setUnitsGreaterThanFilter(value): void {
    this.unitsGreaterThanFilter = value;
  }

  @Mutation
  resetSetActiveTasq(): void {
    this.activeTasq = null;
  }

  @Mutation
  updateTasq({ id, data }: { id: string, data: any }): void {
    const foundTasq = this.allTasqs.find((i) => i.id === id);
    Object.assign(foundTasq, data);
  }

  @Mutation
  setActiveList(listType: string): void {
    this.activeListType = listType;
  }

  @Mutation
  setActivePage(page: string): void {
    this.activePage = page;
  }

  @Mutation
  setIsLoadingTasqs(isLoadingTasqs: boolean): void {
    this.isLoadingTasqs = isLoadingTasqs;
  }

  @Mutation
  setIsLoadingPaginatedTasqs(isLoadingTasqs: boolean): void {
    this.isLoadingPaginatedTasqs = isLoadingTasqs;
  }

  @Mutation
  setIsLoadingSearchTasqs(isLoadingSearchTasqs: boolean): void {
    this.isLoadingSearchTasqs = isLoadingSearchTasqs;
  }

  @Mutation
  resetTasqListLevel() {
    this.setTasqListLevel(accountModule.user != null && accountModule.user.role != null && (accountModule.user.role == 'Operators' || accountModule.user.role == 'FieldOperator') ? 'Pad' : 'Well');
  }

  @Mutation
  setActiveFilter(activeFilter) {
    // @ts-ignore
    Vue.set(this.activeFilters, router.history.current.name, activeFilter)
  }

  @Mutation
  setActiveFilters(defaultFilters) {
    const customFilters = workflowModule.user.customFilters
    let activeFilters = {}
    for (const pageName in defaultFilters) {
      const filter = customFilters.find(
        customFilter => customFilter.id == defaultFilters[pageName]
      );
      activeFilters[pageName] = filter
    }
    Vue.set(this, 'activeFilters', activeFilters)
  }

  @Mutation
  resetActiveFilter() {
    // @ts-ignore
    this.activeFilters[router.history.current.name] = {
      id: '',
      name: '',
      settings: {}
    };
  }

  @Mutation
  incrementTasqViews(tasq) {
    let count = 0
    if (!tasq.views) {
      Vue.set(tasq, "views", {})
    }
    if (tasq.views.hasOwnProperty(workflowModule.user.username)) {
      count = tasq.views[workflowModule.user.username]
    }
    Vue.set(tasq.views, workflowModule.user.username, count+1)
  }

  @Debounce(100)
  @Action
  async getTasqs(list: {
    text?: string,
    val: string,
  }): Promise<void> {
    let level = this.tasqListLevel;
    const isOnlineApp = await isOnline();
    try {
      if (!isOnlineApp) {
        if (this.tasqList && this.tasqList.length && this.producingWells && this.producingWells.length) {
          return;
        }
        this.prepareOfflineMode();
        return;
      }
      this.resetPagination();

      if (level === 'Pad') {
        await this.getTasqsForPadView();
        return;
      }
      if (!this.isLoadingTasqs) {
        this.setIsLoadingTasqs(true);
        this.setPaginationLoading(false);
        this.setTasqStartLimit();

        let todaysTasqResults: any[] = [];
        const completedTasqResults: any[] = [];
        let waitingTasqResults: any[] = [];
        if (this.activePage !== 'Tasq') {
          level = VERTICAL_LIST_DEFAULT_LEVEL;
        }

        if (((level !== 'Pad') || this.activePage !== 'Tasq')) {
          let from = 0;
          let to = 499;
          const variables = {
            Timezone: 'America/Denver',
            Operator: getConfigEnv('OPERATOR_LOWERCASED'),
            Level: VERTICAL_LIST_DEFAULT_LEVEL,
            skipTotalTasqs: true,
            skipTodaysTasqs: false,
            To: to,
            From: from,
            TasqStatus: ['Open'],
            ListType: 'Tasqs',

    };
          let tasqsLists: any = {};

          const {
            data: {
              get_tasq_list: tasqsListsResponse,
            },
          }: GetTasqsListsResponse = await predictionsApolloClient.query({
            query: getTasqsLists,
            variables,
            fetchPolicy: 'network-only',
          });

          tasqsLists = tasqsListsResponse;
          if (tasqsLists.PredictionTypesDict && typeof tasqsLists.PredictionTypesDict === 'string') {
            this.setAllPredictionDistTypes(JSON.parse(tasqsLists.PredictionTypesDict));
          }

          // @ts-ignore
          todaysTasqResults = tasqsLists.TodaysTasqResults;
          // @ts-ignore
          waitingTasqResults = tasqsLists.WaitingOnTasqs;

          const tasqsList = (todaysTasqResults).map(
            (t: any) => mapDefaultTasq(jsonParse(t)),
          );

          const waitingOn = (waitingTasqResults).map(
            (t: any) => mapDefaultTasq(jsonParse(t)),
          );

          this.setWaitingTasqs(waitingOn);
          this.setTasqList({
            data: tasqsList.concat(waitingOn),
          });
          this.setIsLoadingTasqs(false);
          this.setActiveList(list.val);
          if (Number(tasqsLists.TodaysTasqLength) < 500) {
            this.setPaginationLoading(false);
          }

          this.setTasqListLevel(level);

          if (this.savingForOffline) {
            await userPreferenceDB.setItem('tasqListPaginated', this.tasqList);
          }

          if (Number(tasqsLists.TodaysTasqLength) > 500) {
            const counter = Math.ceil((Number(tasqsLists.TodaysTasqLength) - 499) / 500);

            this.setIsLoadingPaginatedTasqs(true);

            for (let index = 0; index < counter; index++) {
              from = to + 1;
              to += 500;

              const listType = 'Tasqs';
              const todaysTasqResultsPaginated: any = [];

              if (navigator.onLine) {
                predictionsApolloClient.query({
                  query: getTasqsLists,
                  variables: {
                    Timezone: 'America/Denver',
                    Operator: getConfigEnv('OPERATOR_LOWERCASED'),
                    Level: VERTICAL_LIST_DEFAULT_LEVEL,
                    // @ts-ignore
                    skipTotalTasqs: true,
                    skipTodaysTasqs: false,
                    To: to,
                    // @ts-ignore
                    From: from,
                    TasqStatus: ['Open'],
                    ListType: listType,

                  },
                  fetchPolicy: 'network-only',
                }).then(async (result: any) => {
                  const { data: { get_tasq_list: paginatedTasqsLists } } = result;

                  for (let x = 0; x < paginatedTasqsLists.TodaysTasqResults.length; x++) {
                    todaysTasqResultsPaginated.push(JSON.parse(paginatedTasqsLists.TodaysTasqResults[x]));
                  }

                  const tasqListPaginated = jsonParse(JSON.stringify(todaysTasqResultsPaginated)).map(
                    (t: TasqJobResponse) => mapDefaultTasq(t),
                  );
                  this.setTasqList({
                    data: tasqListPaginated,
                    concat: true,
                  });
                  if (this.savingForOffline) {
                    if (this.tasqList) {
                      await userPreferenceDB.setItem('tasqListPaginated', this.tasqList);
                    }
                  }
                });
              } else {
                // eslint-disable-next-line no-await-in-loop
                const tasqListPaginated: any = await userPreferenceDB.getItem(`tasqListPaginated${index}`);

                if (tasqListPaginated && tasqListPaginated.length) {
                  this.setTasqList({
                    data: tasqListPaginated,
                    concat: true,
                  });
                }
              }
            }

            sleep(5000).then(() => {
              this.setIsLoadingPaginatedTasqs(false);
            });
            await sleep(1000);
          }
        }
        this.setIsLoadingTasqs(false);
        this.setTasqListLevel(level);

        if ((level !== 'Pad'  && this.producingWells.length < 3)) {
          this.setIsLoadingPaginatedTasqs(true);
          let producingPromise: any = null;

          if (isOnlineApp) {
            producingPromise = (predictionsApolloClient.query({
              query: searchTasqs,
              variables: {
                skipTotalTasqs: false,
                skipTotalProducing: false,
                input: {
                  Timezone: 'America/Denver',
                  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
                  Level: VERTICAL_LIST_DEFAULT_LEVEL,
                  FetchAllProducing: true,
                  IncludeWaitingOnTasqs: true,

                },

              },
              fetchPolicy: 'network-only',
            }));

            producingPromise.then(async (result: any) => {
              const { data: { search_tasqs: newSearchLists } } = result;
              const data: any = JSON.parse(newSearchLists.TotalProducing);

              const producing = data.map(
                (t: TasqJobResponse) => mapDefaultTasq(t),
              );

              console.log(producing.filter(p => p.wellName.includes('SWN')).map(k =>k.wellName));
              this.setProducingWells(producing);
              if (this.producingWells && this.producingWells.length) {
                await userPreferenceDB.setItem('getTasqsListProducing', producing);
              }
              this.setIsLoadingPaginatedTasqs(false);
            }).catch(() => {
              this.setIsLoadingPaginatedTasqs(false);
            });
          }
        }

        await this.getCompletedTasqs();
      }
    } catch (error: any) {
      this.setIsLoadingPaginatedTasqs(false);
      console.error(error.message);
    }
  }

  @Action
  async getCompletedTasqs() {
    const listType = 'Tasqs';
    let completedTasqResults: any[] = [];
    let from = 0;
    let to = 499;
    predictionsApolloClient.query({
      query: getTasqsLists,
      variables: {
        Timezone: 'America/Denver',
        Operator: getConfigEnv('OPERATOR_LOWERCASED'),
        Level: VERTICAL_LIST_DEFAULT_LEVEL,
        To: to,
        // @ts-ignore
        From: from,
        // @ts-ignore
        skipTotalTasqs: true,
        skipTodaysTasqs: true,
        skipWaitingOn: true,
        TasqStatus: ['Closed'],
        ListType: listType,

      },
      fetchPolicy: 'network-only',
    }).then(async (result: any) => {
      const { data: { get_tasq_list: tasqsLists } } = result;

      // @ts-ignore
      completedTasqResults = tasqsLists.CompletedTasqs;

      const completed = (completedTasqResults).map(
        (t: any) => mapDefaultTasq(jsonParse(t)),
      );

      this.setTasqList({
        data: completed,
        listType: 'completedTasqList',
      });

      if (Number(tasqsLists.CompletedTasqLength) > 500) {
        const counter = Math.ceil((Number(tasqsLists.CompletedTasqLength) - 499) / 500);

        for (let index = 0; index < counter; index++) {
          from = to + 1;
          to += 500;

          const todaysTasqResultsPaginated: any = [];
          predictionsApolloClient.query({
            query: getTasqsLists,
            variables: {
              Timezone: 'America/Denver',
              Operator: getConfigEnv('OPERATOR_LOWERCASED'),
              Level: VERTICAL_LIST_DEFAULT_LEVEL,
              // @ts-ignore
              skipTotalTasqs: true,
              skipTodaysTasqs: false,
              To: to,
              // @ts-ignore
              From: from,
              TasqStatus: ['Closed'],
              ListType: listType,

            },
            fetchPolicy: 'network-only',
          }).then(async (result: any) => {
            const { data: { get_tasq_list: paginatedTasqsLists } } = result;

            for (let x = 0; x < paginatedTasqsLists.CompletedTasqs.length; x++) {
              todaysTasqResultsPaginated.push(JSON.parse(paginatedTasqsLists.CompletedTasqs[x]));
            }

            const tasqListPaginated = jsonParse(JSON.stringify(todaysTasqResultsPaginated)).map(
              (t: TasqJobResponse) => mapDefaultTasq(t),
            );

            this.setTasqList({
              data: tasqListPaginated,
              listType: 'completedTasqList',
              concat: true,
            });
          });
        }

        sleep(5000).then(() => {
          this.setIsLoadingPaginatedTasqs(false);
        });

        await sleep(1000);
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  @Debounce(100)
  @Action
  async getTasqsForPadView(): Promise<void> {
    this.resetPagination();

    let level = this.tasqListLevel;
    try {
      if (!this.isLoadingTasqs) {
        this.setIsLoadingTasqs(true);
        this.setPaginationLoading(false);
        this.setTasqStartLimit();
        let to = 99;
        let from = 0;
        let todaysTasqLength: any = 499;
        const todaysTasqResults: any[] = [];
        if (this.activePage !== 'Tasq') {
          level = VERTICAL_LIST_DEFAULT_LEVEL;
        }

        if ((level === 'Pad')) {
          // @ts-ignore

          let searchLists: any;
          const offlinePadData = await localForage.getItem('saveOfflineMyPadList');
          if (navigator.onLine) {
            const {
              data: {
                search_tasqs: newSearchLists,
              },
            }: SearchTasqsResponse = await predictionsApolloClient.query({
              query: searchTasqs,
              variables: {
                skipTotalTasqs: false,
                skipTotalProducing: true,
                input: {
                  Timezone: 'America/Denver',
                  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
                  Level: level,
                  To: to,
                  // @ts-ignore
                  From: from,
                  FetchAllProducing: false,
                  IncludeWaitingOnTasqs: true,

                },
              },
              fetchPolicy: 'network-only',
            });
            searchLists = newSearchLists;
          } else {
            searchLists = offlinePadData;
          }

          this.setTasqListLevel(level);

          for (let x = 0; x < JSON.parse(searchLists.Results).length; x++) {
            todaysTasqResults.push(JSON.parse(searchLists.Results)[x]);
          }

          todaysTasqLength = searchLists.ResultTotal;
          this.setTotalTasqPagesLength(todaysTasqLength);
          const tasqsList = (todaysTasqResults).map(
            (t: TasqJobResponse) => mapDefaultTasq(t),
          );

          const additionalPadNestedTasqs: any[] = [];
          for (let t = 0; t < tasqsList.length; t++) {
            if (tasqsList[t].wells.length == 0) {
              continue;
            }
            for (let r = 0; r < tasqsList[t].wells.length; r++) {
              tasqsList[t].wells[r] = mapDefaultTasq(tasqsList[t].wells[r]);
              additionalPadNestedTasqs.push(tasqsList[t].wells[r]);
            }
          }

          this.setAdditionalPadNestedTasqs(additionalPadNestedTasqs);
          this.setTasqList({
            data: tasqsList,
          });
          this.setIsLoadingTasqs(false);
        }
        this.setIsLoadingTasqs(false);
        this.setTasqListLevel(level);

        if (todaysTasqLength > 100) {
          const counter = Math.ceil((Number(todaysTasqLength) - 99) / 100);

          for (let index = 0; index < counter; index++) {
            from = to + 1;
            to += 100;
            predictionsApolloClient.query({
              query: searchTasqs,
              variables: {
                skipTotalTasqs: false,
                skipTotalProducing: true,
                input: {
                  Timezone: 'America/Denver',
                  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
                  Level: level,
                  To: to,
                  // @ts-ignore
                  From: from,
                  FetchAllProducing: false,
                  IncludeWaitingOnTasqs: true,

                },
              },
              fetchPolicy: 'network-only',
            }).then((result: any) => {
              const { data: { search_tasqs: newSearchListPaginated } } = result;
              const data: any = JSON.parse(newSearchListPaginated.Results);
              const paginatedPads = data.map(
                (t: TasqJobResponse) => mapDefaultTasq(t),
              );
              const additionalPadNestedTasqs: any[] = [];
              for (let t = 0; t < paginatedPads.length; t++) {
                if (paginatedPads[t].wells.length == 0) {
                  continue;
                }
                for (let r = 0; r < paginatedPads[t].wells.length; r++) {
                  paginatedPads[t].wells[r] = mapDefaultTasq(paginatedPads[t].wells[r]);
                  additionalPadNestedTasqs.push(paginatedPads[t].wells[r]);
                }
              }

              this.updateAdditionalPadNestedTasqs(additionalPadNestedTasqs);

              this.setTasqList({
                data: paginatedPads,
                concat: true,
              });
            });
          }
        }
        this.setIsLoadingPaginatedTasqs(true);
        predictionsApolloClient.query({
          query: searchTasqs,
          variables: {
            skipTotalTasqs: false,
            skipTotalProducing: false,
            input: {
              Timezone: 'America/Denver',
              Operator: getConfigEnv('OPERATOR_LOWERCASED'),
              Level: VERTICAL_LIST_DEFAULT_LEVEL,
              FetchAllProducing: true,
              IncludeWaitingOnTasqs: true,
            }
          },
          fetchPolicy: 'network-only',
        }).then((result: any) => {
          const { data: { search_tasqs: newSearchLists } } = result;
          const data: any = JSON.parse(newSearchLists.TotalProducing);

          const producing = data.map(
            (t: TasqJobResponse) => mapDefaultTasq(t),
          );
          this.setPadNestedTasqs(producing);
          this.setIsLoadingPaginatedTasqs(false);
        }).catch(() => {
          this.setIsLoadingPaginatedTasqs(false);
        });
      }
    } catch (error: any) {
      this.setIsLoadingPaginatedTasqs(false);
      console.error(error.message);
    }
  }

  @Action
  async prepareOfflineMode() {
    this.setIsLoadingPaginatedTasqs(true);
    const producingWells: any = await userPreferenceDB.getItem('getTasqsListProducing');
    const activeWells: any = await userPreferenceDB.getItem('tasqListPaginated');

    if (producingWells && producingWells.length) {
      this.setProducingWells(producingWells);
    }

    if (activeWells && activeWells.length) {
      this.resetPagination();
      this.setTasqList({
        data: activeWells,
      });
    }

    this.setIsLoadingPaginatedTasqs(false);
  }

  @Action
  async searchTasqByName({ level = VERTICAL_LIST_DEFAULT_LEVEL, wellName = '' }) {
    const todaysTasqResults: any[] = [];

    const input = {
      skipTotalTasqs: true,
      skipTotalProducing: true,
      input: {
        Timezone: 'America/Denver',
        Operator: getConfigEnv('OPERATOR_LOWERCASED'),
        Level: level,
        From: 0,
        To: 10,
        SubstringFilter: wellName,
        IncludeWaitingOnTasqs: true,
      },
    };

    const {
      data: {
        search_tasqs: tasqsLists,
      },
    }: SearchTasqsResponse = await predictionsApolloClient.query({
      query: searchTasqs,
      variables: input,
      fetchPolicy: 'network-only',
    });

    for (let i = 0; i < JSON.parse(tasqsLists.Results).length; i++) {
      todaysTasqResults.push(JSON.parse(tasqsLists.Results)[i]);
    }

    const allRelatedTasqs: any = jsonParse(JSON.stringify(todaysTasqResults)).map(
      (t: TasqJobResponse) => mapDefaultTasq(t),
    );

    if (level === 'Pad') {
      const additionalPadNestedTasqs: any = [];
      for (let t = 0; t < allRelatedTasqs.length; t++) {
        if (allRelatedTasqs[t].wells.length == 0) {
          continue;
        }
        for (let r = 0; r < allRelatedTasqs[t].wells.length; r++) {
          allRelatedTasqs[t].wells[r] = mapDefaultTasq(allRelatedTasqs[t].wells[r]);
          additionalPadNestedTasqs.push(allRelatedTasqs[t].wells[r]);
        }
      }
      this.setAdditionalPadNestedTasqs(additionalPadNestedTasqs);
    }

    if (allRelatedTasqs && allRelatedTasqs.length) {
      return (allRelatedTasqs[0]);
    }

    return null;
  }

  @Action
  async getTasq({
    PredictionID,
    Type = 'tasq',
    updateTasqInList = true
  }) {
    const {
      data: {
        get_tasq: {
          prediction: Prediction,
        },
      },
    } : any = await workflowApolloClient.query({
      query: getTasq,
      variables: {
        operator: getConfigEnv('OPERATOR_LOWERCASED'),
        prediction_id: PredictionID,
      },
      fetchPolicy: 'network-only',
    });

    if (Type === 'workTicket') {
      const workTicket = mapDefaultWorkTicketTasqs(JSON.parse(Prediction));
      this.setActiveWorkTicket(workTicket);
    } else {
      const tasq = mapDefaultTasq(JSON.parse(Prediction),'',false);
      if(updateTasqInList){
        this.updateTasqDetails(tasq);
      }
      this.setActiveTasqExplicit(tasq);
    }
  }

  @Action
  async getRawTasq({
    PredictionID,
    Type = 'tasq',
  }) {
    const {
      data: {
        get_tasq: {
          prediction: Prediction,
        },
      },
    } : any = await workflowApolloClient.query({
      query: getTasq,
      variables: {
        operator: getConfigEnv('OPERATOR_LOWERCASED'),
        prediction_id: PredictionID,
      },
      fetchPolicy: 'network-only',
    });

    if (Type === 'workTicket') {
      const workTicket = mapDefaultWorkTicketTasqs(JSON.parse(Prediction));
      this.updateWorkTicketInList(workTicket);
      return workTicket;
    }
    const tasq =  mapDefaultTasq(JSON.parse(Prediction),'',false);
    this.updateTasqInList({ data: tasq });
    return (tasq);
  }

  @Action
  async getWaitList() {
    const isOnlineApp = await isOnline();
    if (isOnlineApp) {
      const {
        data: {
          get_my_waitlist: {
            results: waitListResults,
          },
        },
      } : any = await workflowApolloClient.query({
        query: getMyWaitList,
        variables: {
          input: {
      Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        },
        fetchPolicy: 'network-only',
      });
      const waitingTasqs = waitListResults.map(
        (r) => mapDefaultTasq(jsonParse(r)),
      );

      this.setWaitingTasqs(waitingTasqs);
      await userPreferenceDB.setItem('getWaitList', waitingTasqs);
    } else {
      const result = await userPreferenceDB.getItem('getWaitList');
      if (result) {
        this.setWaitingTasqs(result);
      }
    }
  }

  @Action
  async getTasqEventHistoryList(worklflowTaskID) {
    const isOnlineApp = navigator.onLine;
    this.setTasqEventHistoryList([]);
    if (isOnlineApp) {
      const {
        data: {
          get_tasq_event_history: {
            Results: tasqEventResults,
          },
        },
      } : any = await workflowApolloClient.query({
        query: getTasqEventHistory,
        variables: {
          input: {
            WorkflowTaskID: worklflowTaskID,
          },
        },
        fetchPolicy: 'network-only',
      });
      this.setTasqEventHistoryList(tasqEventResults);
    }
  }

  @Action
  async getTasqListResponses() {
    const isOnlineApp = navigator.onLine;
    if (isOnlineApp) {
      this.setIsLoadingTasqResponses(false);
    }
  }

  @Action
  async getSubmittedForms(id: any = null) {
    try {
      const submittedFormResult: any = [];
      if (navigator.onLine) {
        const {
          data: {
            get_submitted_form_responses: submittedForms,
          },
      } : any = await workflowApolloClient.query({
          query: getSubmittedForms,
          variables: {
            input: {
              Operator: getConfigEnv('OPERATOR_LOWERCASED'),

            },

          },
          fetchPolicy: 'network-only',
      });
        const tasqsList = (JSON.parse(submittedForms.Results)).map(
          (t: TasqJobResponse) => mapDefaultTasq(t),
        );
        this.setSubmittedTasqs(tasqsList);
        await userPreferenceDB.setItem('submittedForms', tasqsList);
        return [];
      }

      const result = await userPreferenceDB.getItem('submittedForms');

      if (result) {
        this.setSubmittedTasqs(result);
        return result;
      }
      return [];
    } catch (e) {
      const result = await userPreferenceDB.getItem('submittedForms');

      if (result) {
        this.setSubmittedTasqs(result);
        return result;
      }
      throw new Error(e);
    }
  }

  get tasqById() {
    return (id: string): TasqJob|undefined => [...this.tasqList, ...this.producingWells, ...this.completedTasqList, ...this.waitingTasqList, ...this.padNestedTasqs, ...this.additionalPadNestedTasqs].find((t) => t.id?.toLowerCase() === id?.toLowerCase());
  }

  get workTicketById() {
    return (id: string): TasqJob|undefined => [...this.workTickets].find((t) => t.id?.toLowerCase() === id?.toLowerCase());
  }

  get activeTasqsList() {
    return this.activeListType;
  }

  get tasqs() {
    return (selectedRoutes:any=null, selectedAreas:any=null, selectedPads:any=null) => {
      const settings = this.activeFilterByPage().settings;
      const tasqList = [...this.tasqList];
      if (this.isMobileView) {
        return [...tasqList, ...this.producingWells];
      }
      if (this.tasqListLevel === 'WellView') {
        return [...tasqList, ...this.producingWells];
      }
      if (this.tasqListLevel === 'WorkTicket') {
        const workTickets = this.workTickets;
        return [...workTickets];
      }

      if (!selectedRoutes && !selectedAreas) {
        selectedRoutes = settings.selectedRoutes || []
        selectedAreas = settings.selectedAreas || []
      }

      if(!selectedPads) {
        selectedPads = settings.selectedPads || []
      }

      if (
        this.tasqListLevel === 'Well' ||
        (this.isMobileView || this.textSearchValue || selectedRoutes.length || selectedAreas.length || selectedPads.length)
      ) {
        const newProducingList: any = [];
        const tasqs = this.allTasqs;
        this.producingWells.forEach((well) => {
          if (!tasqs.find((tasq) => tasq.wellName === well.wellName)) {
            newProducingList.push(well);
          }
        });
        return [...tasqList, ...newProducingList];
      }
      return tasqList;
    }
  }

  // Work ticket codebase

  workTickets: any = []
  filteredWorkTickets: any = []

  @Mutation
  setWorkTickets(data): void {
    this.workTickets = data;
  }

  @Mutation
  setFilteredWorkTickets(data): void {
    this.filteredWorkTickets = data;
  }

  @Action
  async getWorkTicketTasqs() {
    try {
      this.setIsLoadingTasqs(true);
      const {
        data: {
          get_work_ticket_tasqs: {
            Results: workTicketsResult,
          },
        },
      } : any = await workflowApolloClient.query({
        query: getWorkTickets,
        variables: {
          input: {
            Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        },
        fetchPolicy: 'network-only',
      });
      const workTicketList = (JSON.parse(workTicketsResult));
      const workTickets = workTicketList.map(
        (t: any) => mapDefaultWorkTicketTasqs(t),
      );
      this.setWorkTickets(workTickets);
      this.setIsLoadingTasqs(false);
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  async getCountOpenTasqsForJob({ assetName, filterBy, filterByValue }) {
    try {
      const {
        data: {
          count_open_tasqs_for_job: {
            Count: tasqCount,
          },
        },
      }: any = await workflowApolloClient.query({
        query: getCountTasqForJob,
        variables: {
          input: {
            FilterBy: filterBy,
            FilterByValue: filterByValue,
            AssetName: assetName,
            Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        },
        fetchPolicy: 'network-only',
      });
      return tasqCount;
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  async getCountOpenTasqsForAsset({ assetName }) {
    try {
      const {
        data: {
          count_open_tasqs_for_asset: {
            Count: tasqCount,
          },
        },
      }: any = await workflowApolloClient.query({
        query: getCountTasqForAsset,
        variables: {
          input: {
            TasqType: 'Work Ticket',
            AssetName: assetName,
            Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        },
        fetchPolicy: 'network-only',
      });
      return (tasqCount);
    } catch (error) {
      console.error(error);
    }
  }



  @Action
  async getDailyWorkTicketData({ predictionID }) {
    try {
      const {
        data: {
          get_daily_work_ticket_data: {
            Result: dailyWorkTicketDataResult,
          },
        },
      }: any = await workflowApolloClient.query({
        query: getDailyWorkTicketData,
        variables: {
          input: {
            PredictionID: predictionID,
          },
        },
        fetchPolicy: 'network-only',
      });

      const dailyWorkTicketData = JSON.parse(dailyWorkTicketDataResult);
      if (dailyWorkTicketData && dailyWorkTicketData.length) {
        const details = dailyWorkTicketData.map((data) => ({
          dailyCostTable: data.DailyCostsTable || [],
          dailyDate: new Date(data.DailyDate).toDateString(),
          dateCreated: data.DateCreated,
          hoursSpentToday: data.HoursSpentToday,
          id: data.ID,
          nextTwentyFourHourSummary: data.NextTwentyFourHourSummary,
          predictionID: data.PredictionID,
          timezone: data.Timezone,
          twentyFourHourSummary: data.TwentyFourHourSummary,
        })).filter((table) => new Date(table.dailyDate).getTime());

        return details;
      }

      return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  @Action
  updateTasqViewsCount({predictionID}) {
    if (workflowModule.user.isTestUser) {
      return
    }
    return new Promise((resolve) => {
      workflowApolloClient.mutate({
        mutation: updateTasqViewsCountMutation,
        variables: {
          input: {
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
            prediction_id: predictionID,
            username: workflowModule.user.username,
          },
        }
      }).then(({ data: { update_tasq_views } } : any) => {
        let tasq = this.tasqById(predictionID)
        if (tasq) {
          this.incrementTasqViews(tasq)
        }
        resolve(null)
      })
    })
  }


  @Action
  async getGoodCatchDefermentByTime() {
  const now = new Date();

  // Get the first day of the month.
  const thirtyDaysAgo = new Date(now.getTime() - (30 * 24 * 60 * 60 * 1000));

  // Get the timestamp for the first day of the month.
  const timestamp = thirtyDaysAgo.getTime();


  const { data: { get_good_catch_deferment_for_time: {
    TotalDeferment: Result

  } } } : any = await workflowApolloClient.query({
    query: getGoodCatchDefermentForTime,
    variables: {
      input: {
        Operator: getConfigEnv('OPERATOR_LOWERCASED'),
        FromTimestamp: `${timestamp}`
      },
    },
    fetchPolicy: 'network-only',
  })

  return Result
  }

  @Action
  updateTasqGoodCatch({predictionID, isGoodCatch}) {
    return new Promise((resolve) => {
      workflowApolloClient.mutate({
        mutation: updateTasqMutation,
        variables: {
          input: {
            PredictionID: predictionID,
            GoodCatch: isGoodCatch,
            ResponseTimestamp: `${new Date().getTime()}`
          },
        }
      }).then(({ data: { update_tasq } } : any) => {
        resolve(null)
      })
    })
  }
}

export default getModule(TasqsListModule);
